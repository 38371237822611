<template>
  <div class="test">
    <my-header title="开始考试"></my-header>
    <div class="info">
      <p class="title">{{ testInfo.examTitle }}</p>
      <p class="end">距考试结束还有</p>
      <p class="time">
        {{ countdown.hour }} : {{ countdown.minute }} : {{ countdown.second }}
      </p>
    </div>
    <div class="topic">
      <div class="superscript">
        <div class="no">{{ pagination.page }}</div>
        <div class="type">
          <span v-if="test.queType == 1">单选题</span>
          <span v-if="test.queType == 2">多选题</span>
          <span v-if="test.queType == 3">问答题</span>
        </div>
      </div>
      <p class="title">{{ test.queContent.title }}:</p>
      <div v-if="test.queType == 1 || test.queType == 2">
        <div
          v-for="(item, index) in test.optionsList"
          :key="index"
          class="list"
          :style="selected(item) ? { border: '1px solid #EA5514' } : {}"
          @click="slectOption(item)"
        >
          <div style="display: table-cell; width: 5rem">
            <p>{{ item.option }}.</p>
          </div>
          <div style="display: table-cell">
            <p>{{ item.concent }}</p>
          </div>
        </div>
      </div>
      <div v-if="test.queType == 3">
        <p style="font-size: 3rem">答：</p>
        <textarea
          class="area"
          rows="4"
          cols="50"
          v-model="answer[pagination.page - 1].queAnswer"
        />
      </div>
    </div>
    <div class="footer">
      <span class="left" @click="last"
        ><van-icon name="arrow-left" />上一页</span
      >
      <span class="center"
        ><van-field
          @blur="changePage"
          style="width: 10rem"
          max="100"
          v-model="pagination.fakePage"
          maxlength="3"
          type="number"
          autosize
          :border="true"
        />
        / {{ pagination.total }}</span
      >
      <span class="right" @click="next">下一页<van-icon name="arrow" /></span>
    </div>
    <van-button
      type="default"
      color="linear-gradient(90deg, #FB7A08, #FE6113)"
      class="submit"
      @click="submit"
      >答完交卷</van-button
    >
    <!-- 确认提交弹窗 -->
    <van-overlay :show="show" z-index="3" class="confirmModal">
      <div class="center" v-if="!loading">
        <p>确认提交考卷，计算成绩？</p>
        <van-button
          class="button"
          color="#B5B5B6"
          style="margin-right: 2.5rem"
          @click="cancel"
          >取消</van-button
        >
        <van-button
          class="button"
          color="linear-gradient(90deg, #FB7A08, #FE6113)"
          @click="confirm"
          >确定</van-button
        >
      </div>
      <div class="load" v-else>
        <img src="@/assets/image/my/close.png" class="close" @click="finish" />
        <img src="@/assets/image/my/sandClock.png" class="sand" />
        <p>谢谢您的参与，成绩将稍后公布！</p>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import list from './list'
import { getTestInfo, submitTest, recordTime } from '@/api/my/test'
export default {
  data() {
    return {
      testTime: '1800',
      setInt: null,
      pagination: {
        fakePage: 1,
        page: 1,
        total: 100,
      },
      // 当前显示的试题 单选1 多选题2 问答3
      test: {
        queType: '',
        optionsList: [
          {
            option: '',
            concent: '',
          },
        ],
        queContent: { title: '' },
      },
      testList: [],
      testInfo: {}, //考试信息
      //答案列表
      answer: [],
      show: false,
      loading: false,
    }
  },
  mixins: [list],
  created() {
    this.getTestList()
    this.timing()
  },
  computed: {
    // 计时
    countdown() {
      let obj = {}
      obj.second = parseInt(this.testTime % 60).toString()
      obj.minute = parseInt((this.testTime / 60) % 60).toString()
      obj.hour = parseInt((this.testTime / 60 / 60) % 24).toString()
      for (const key in obj) {
        if (obj[key].length < 2) {
          obj[key] = '0' + obj[key]
        }
      }
      return obj
    },
    // 1单选 2多选 3判断 4填空 5问答
    selected() {
      return function (params) {
        if (
          this.test.queType == 1 &&
          this.answer[this.pagination.page - 1].queAnswer == params.option
        ) {
          return true
        } else if (
          this.test.queType == 2 &&
          this.answer[this.pagination.page - 1] &&
          this.answer[this.pagination.page - 1].queAnswer
        ) {
          for (const item of this.answer[this.pagination.page - 1].queAnswer) {
            if (item == params.option) {
              return true
            }
          }
        }
        return false
      }
    },
  },
  methods: {
    // 倒计时
    timing() {
      this.setInt = setInterval(() => {
        if (this.testTime > 0) {
          this.testTime -= 1
        } else {
          this.$toast.success('考试时间到!自动交卷成功!')
          this.confirm()
          this.show = true
          clearInterval(this.setInt)
          this.setInt = null
        }
      }, 1000)
    },
    getTestList() {
      getTestInfo(this.$store.getters.user.id, this.$route.query.examId).then(
        (res) => {
          console.log(res)
          this.testInfo = res.result
          this.testList = [
            ...res.result.singleQuestions,
            ...res.result.multipleQuestions,
            ...res.result.essayQuestions,
          ]
          this.pagination.total = this.testList.length

          // 初始化要显示的试题信息
          this.dealTest()

          // 初始化答案列表
          this.answer.length = this.testList.length
          for (let i = 0; i < this.testList.length; i++) {
            this.answer[i] = {
              queId: this.testList[i].queId,
              queType: this.testList[i].queType,
              queScore: this.testList[i].score,
            }
          }
          this.testTime = res.result.duration

          if (res.result.userStartTime) {
            // 转化为时间戳进行计算
            let date = new Date(res.result.userStartTime)
            let nowDate = new Date()
            //已经过去的时间
            let pastTime = (Date.parse(nowDate) - Date.parse(date)) / 1000
            // 还剩下的时间
            this.testTime = this.testTime - pastTime
            if (this.testTime < 0) {
              this.testTime = 0
            }
          } else {
            //如果不存在用户开始考试时间字段 则发送请求记录当前时间
            recordTime(
              this.$store.getters.user.id,
              this.$route.query.examId
            ).then((res) => {
              console.log(res)
            })
          }
        }
      )
    },
    // 选择
    slectOption(item) {
      let page = this.pagination.page - 1
      if (this.test.queType == 1) {
        //单选
        if (!this.selected(item)) {
          this.$set(this.answer, page, {
            queAnswer: item.option,
            queId: this.test.queId,
            queType: this.test.queType,
            queScore: this.test.score,
          })
        } else {
          this.$set(this.answer[page], 'queAnswer', '')
        }
      } else if (this.test.queType == 2) {
        //多选

        // 如果不存在就重置为空
        if (!this.answer[page].queAnswer) {
          this.answer[page].queAnswer = []
        }
        if (!this.selected(item)) {
          // 保存之前的答案
          let arr = this.answer[page].queAnswer
          arr.push(item.option)
          this.$set(this.answer, page, {
            queAnswer: arr,
            queId: this.test.queId,
            queType: this.test.queType,
            queScore: this.test.score,
          })
        } else {
          let arr = this.answer[page].queAnswer
          this.$set(
            this.answer[page],
            'queAnswer',
            arr.filter((query) => {
              return query != item.option
            })
          )
        }
      }
    },
    last() {
      if (this.pagination.fakePage == 1) {
        return
      }
      this.pagination.fakePage -= 1
      this.pagination.page = this.pagination.fakePage
      this.dealTest()
    },
    next() {
      if (this.pagination.fakePage == this.testList.length) {
        return
      }
      this.pagination.fakePage = Number(this.pagination.page) + 1
      this.pagination.page = this.pagination.fakePage
      this.dealTest()
    },
    dealTest() {
      this.test = this.testList[this.pagination.page - 1]
      this.test.optionsList = []

      // 仅为单选 多选时候处理选项
      if (this.test.queType == '1' || this.test.queType == '2') {
        for (
          let index = 0;
          index < this.test.queContent.queOptions.length;
          index++
        ) {
          this.test.optionsList.push({
            option: this.optionAllList[index],
            concent: this.test.queContent.queOptions[index].queOption,
          })
        }
      }
    },
    changePage() {
      if (this.pagination.fakePage > this.testList.length) {
        this.pagination.page = this.testList.length
        this.pagination.fakePage = this.testList.length
      } else if (this.pagination.page < 1) {
        this.pagination.page = 1
        this.pagination.fakePage = 1
      } else {
        this.pagination.page = this.pagination.fakePage
      }
      this.dealTest()
    },
    submit() {
      this.show = true
    },
    cancel() {
      this.show = false
    },
    confirm() {
      let answer = [...this.answer]
      // 多选题的数组转化为字符串
      for (const item of answer) {
        if (item && item.queType && item.queType == 2) {
          if (item.queAnswer) {
            item.queAnswer = item.queAnswer.toString()
          } else {
            item.queAnswer = ''
          }
        }
      }

      // paperid都一样 任意取一个
      let paperId
      for (const item of this.testList) {
        if (item.paperId) {
          paperId = item.paperId
          break
        }
      }
      let obj = {
        examId: this.testInfo.examId,
        paperId: paperId,
        examineeId: this.$store.getters.user.id,
        questions: answer,
      }
      console.log(obj)
      submitTest(obj).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.$toast.success('提交成功!')
        } else {
          this.$toast.fail('提交失败!')
          return
        }
      })
      this.loading = true
    },
    finish() {
      this.$utils.goBack()
    },
  },
}
</script>
<style lang="less" scoped>
.test {
  padding: 64px 7rem 0 4rem;
  .info {
    .title {
      font-size: 3.5rem;
      font-weight: 500;
      color: #ea5514;
    }
    .end {
      font-size: 3.25rem;
      font-weight: 400;
      color: #666666;
      margin-top: 48px;
    }
    .time {
      font-size: 7.5rem;
      font-weight: 400;
      color: #010101;
    }
  }
  .topic {
    .superscript {
      margin-top: 89px;
      border: 2px solid #ea5514;
      width: fit-content;
      .no {
        background: #ea5514;
        display: inline-block;
        font-size: 3rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 55px;
        width: 3.25rem;
        text-align: center;
      }
      .type {
        display: inline-block;
        font-size: 3rem;
        font-weight: 400;
        color: #ea5514;
        line-height: 55px;
      }
    }
    .title {
      margin: 2.5rem 0 1.5rem 0;
      font-size: 3rem;
      font-weight: 400;
      color: #000000;
    }
    .list {
      margin-top: 56px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #b5b5b6;
      border-radius: 16px;
      padding: 3rem 2rem 32px 3.25rem;
      vertical-align: super;
      p {
        font-size: 3rem;
        font-weight: 400;
        color: #010101;
        // line-height: 55px;
      }
    }
    .area {
      width: 100%;
      height: 645px;
      border: 4px solid #b5b5b6;
      border-radius: 16px;
      font-size: 48px;
      padding: 12px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 300px;
    span {
      font-size: 48px;
      font-weight: 400;
      color: #ea5514;
    }
  }
  .submit {
    position: fixed;
    bottom: 250px;
    left: 4rem;
    width: 72.25rem;
    height: 128px;
    z-index: 2;
    border-radius: 8px;
    color: #ffffff;
  }
  .confirmModal {
    text-align: center;
    padding: 0 10rem 0 10rem;
    .center {
      margin-top: 100%;
      background-color: #ffffff;
      padding: 200px 1rem 11.5rem 1rem;
      border-radius: 2rem;
      p {
        font-size: 3.375rem;
        font-weight: 500;
        color: #ea5514;
        margin-bottom: 78px;
      }
      .button {
        width: 237px;
        height: 85px;
        background: #b5b5b6;
        border-radius: 4px;
        font-size: 40px;
      }
    }
    .load {
      height: 600px;
      background-color: #ffffff;
      border-radius: 16px;
      margin-top: 100%;
      position: relative;
      .close {
        width: 5.125rem;
        position: absolute;
        right: 2rem;
        top: 2rem;
      }
      .sand {
        width: 5.5rem;
        margin-top: 160px;
      }
      p {
        font-size: 3.5rem;
        font-weight: 400;
        margin-top: 60px;
        width: 48%;
        margin-left: 26%;
      }
    }
  }
  ::v-deep .van-cell {
    display: inline;
    padding: 0;
  }
  ::v-deep .van-field__control {
    display: inline-block;
    width: 132px;
    height: 70px;
    border: 1px solid #b5b5b6;
    color: #b5b5b6;
    border-radius: 4px;
  }
  ::v-deep .van-field__value {
    display: inline;
  }
  ::v-deep .van-field__body {
    display: inline;
  }
}
</style>
